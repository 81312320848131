import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';

const Header = () => {
  return (
    <header>
      <div className="restaurant-name">
      <img src="./Images/LogoWithTextColor.png" alt="Tanjay Logo" className='headerLogo'/>
      </div>
      <nav>
        <ul>
          <li><Link to="/">Meny</Link></li>
          <li><Link to="/contact">Kontakt</Link></li>
          <li><Link to="/about">Om Oss</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
